import { ApplicationType, Role } from './../../services/api/interfaces';
import _ from 'lodash';
import * as yup from 'yup';
import {
  ApplicationRoleView,
  CustomerRoleView,
  IntegrationRoles,
  IntegrationRoleView,
  SpringboardRoleMeta,
  SpringboardRoleTypes,
  SupportedUserRoles,
  UserRoleView,
} from '../../services/api/interfaces';

export interface UserFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  customerRole?: CustomerRoleView;
  customModelRole?: Role;
  applicationRoles?: ApplicationRoleView[];
  integrationRoles?: { [key: string]: IntegrationRoleView[] };
}

let springboardRoles: SpringboardRoleMeta[];
let integrationRoles: IntegrationRoles[];

export const getUserFormSchema = (mode: 'create' | 'edit' | 'view' = 'create') => {
  const hints = mode === 'edit' ? ['readonly'] : undefined;
  return {
    type: 'object',
    properties: {
      firstName: {
        type: 'string',
        title: 'First Name',
        description: "Enter the user's first name.",
        hints,
      },
      lastName: {
        type: 'string',
        title: 'Last Name',
        description: "Enter the user's last name.",
        hints,
      },
      email: {
        type: 'string',
        title: 'Email',
        description: "Enter the user's email address.",
        hints,
      },
    },
    required: ['firstName', 'lastName', 'email'],
  };
};

export const getCustomerRoleFormSchema = () => {
  return {
    type: 'object',
    properties: {
      customerRole: {
        type: 'object',
        role: {
          type: 'string',
          title: 'Roles',
          description: 'User can add and remove users, and administer all applications',
          enum: ['admin', 'user'],
          default: 'user',
        },
        id: {
          type: 'string',
          hints: ['hidden'],
        },
        level: {
          type: 'string',
          default: 'CUSTOMER',
          hints: ['hidden'],
        },
      },
    },
  };
};

export const getApplicationRoleFormSchema = (schemaKey: string) => {
  return {
    type: 'object',
    properties: {
      [schemaKey]: {
        type: 'array',
        items: {
          type: 'object',
          role: {
            type: 'string',
            title: '',
            description: '',
            enum: [],
            default: '',
          },
          id: {
            type: 'string',
            title: '',
            description: '',
            hints: ['hidden'],
          },
          level: {
            type: 'string',
            default: 'APPLICATION',
            hints: ['hidden'],
          },
          name: {
            type: 'string',
            hints: ['hidden'],
          },
        },
      },
    },
  };
};

// tslint:disable-next-line
export const customerLevelRoleNameMap = () => {
  return {
    admin: 'Workspace Owner',
    user: 'Workspace Member',
  };
};

export const setSpringboardRoles = (supportedUserRoles: SupportedUserRoles) => {
  springboardRoles = supportedUserRoles.springboardRoles.concat({
    id: 'NO ACCESS',
    name: 'No Access',
    description: 'Cannot view the application.',
    resourceLevel: SpringboardRoleTypes.APPLICATION,
  });
};

export const getSpringboardRoleOptions = (sbRoleType: SpringboardRoleTypes, appType?: ApplicationType | string) => {
  return springboardRoles.filter(
    sbr => sbr.resourceLevel === sbRoleType && (!sbr.workloadType || (appType && sbr.workloadType?.type === appType))
  );
};

export const getIntegrationRoleOptions = templateId => {
  return integrationRoles.find(ir => ir.integrationTemplate.id === templateId).roles;
};

export const setIntegrationRoles = (supportedUserRoles: SupportedUserRoles) => {
  integrationRoles = supportedUserRoles.integrationRoles;
};

export const getSBRoleName = (sbRole, sbRoleType: SpringboardRoleTypes) => {
  return springboardRoles.find(sbr => sbr.id === sbRole && sbr.resourceLevel === sbRoleType)?.name;
};

export const getSBRoleDescription = (sbRole, sbRoleType: SpringboardRoleTypes) => {
  return springboardRoles.find(sbr => sbr.id === sbRole && sbr.resourceLevel === sbRoleType)?.description;
};

export const getIntegrationLevelRoleName = (irRole, irTemplateId) => {
  const roles = integrationRoles.find(ir => ir.integrationTemplate.id === irTemplateId).roles;
  return roles.find(role => role.id === irRole).name;
};

export const getIntegrationRolesByTemplateId = templateId => {
  return integrationRoles.find(ir => ir.integrationTemplate.id === templateId).roles.map(r => r.id);
};

export const getAppIntegrationRoleDescription = (irRole, irTemplateId) => {
  const roles = integrationRoles.find(ir => ir.integrationTemplate.id === irTemplateId).roles;
  return roles.find(role => role.id === irRole).description;
};

export const buildRoleWithMetaData = (
  roleValue: UserRoleView | IntegrationRoleView | ApplicationRoleView,
  metaDataFields: Array<keyof UserRoleView | keyof IntegrationRoleView | keyof ApplicationRoleView>
) => {
  const metadata = _.pick(roleValue, metaDataFields);
  return { ...roleValue, roleMetaData: metadata };
};

export const USER_YUP_SCHEMA = yup.object().shape({
  firstName: yup.string().label('First Name').required(),
  lastName: yup.string().label('Last Name').required(),
  email: yup.string().label('Email').required().email(),
});
